.Autocomplete {
	position: relative;
}
.Autocomplete-input-wrapper {
	position: relative;
}

.Autocomplete-input.fb-input {
	padding-right: 32px;
	text-overflow: ellipsis;
}

.Autocomplete-button {
	position: absolute;
	width: 32px;
	height: 32px;
	top: 2px;
	right: 2px;
	font-size: 16px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	background: @color-white;
}

.Autocomplete-suggestionList {
	position: absolute;
	top: 36px; // input height
	left: 0;
	width: 100%;
	//height: 360px;
	//max-height: 144px;
	overflow-y: scroll;
	background: @autocomplete-bg;
	text-align: left;
	transition: transform .3s ease;
	transform-origin: top;
}
.Autocomplete-suggestionList.open {
	transform: scaleY(1);
}
.Autocomplete-suggestionList.closed {
	transform: scaleY(0);
}

.Autocomplete-suggestionItem {
	display: block;
	cursor: pointer;
	padding: 10px;
	border-bottom: 1px solid @color-highlight;
	&:nth-child(even) { background: @color-white; }
	&.active {
		background: @autocomplete-bg-selected;
		color: @color-highlight;
	}
}
