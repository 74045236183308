
.Nav {
	pointer-events: none;
}

.Nav-Button {
	pointer-events: all;
}

.Nav-Buttons {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

.Nav-Button {
	text-align: center;
	font-size: @fb-fs-heading; // desktop
	font-weight: @font-weight-bold;
	padding-top: @padding-m;
	padding-bottom: @padding-m;
}

.Nav-Button.disabled {
	opacity: .5;
}

.App.integrated .Nav-Button {
	display: none;
}

.visible-focus .Nav-Button:focus { box-shadow: @focus-outline; }

@media screen and (max-width: @breakpoint-tablet-max) {
	.Nav {
		background:	@color-highlight;
	}
	.Nav-Button {
		color: @color-white;
		width: 50%;
		flex-grow: 1; // if there's just a single button instead of two
	}

	.App.fullscreen .Nav {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 2;
		// ATTENTION if this changes, the exact same height has to be used in the Autocomplete.tsx height hack
		height: 78px; // should be height of Nav-Button
	}

	.Nav:before {
		content: '';
		display: block;
		background-image: url("/img/fb/schwung-1200x132.svg"); // 1165px x 89px
		padding-bottom: calc(~"100% *" @schwung-height-mobile/@schwung-width-mobile ~"+ 2px");
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		position: relative;
	}
	.App.fullscreen .Nav:before {
		position: absolute;
		top: -1px;
		pointer-events: none;
	}

	.App.fullscreen .Nav:after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		width: 100%;
		height: 30px;
		margin-top: -30px;
		background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
	}
	.App.fullscreen .Nav-Button {
		padding-top: @padding-m + 20px;
	}
}

// TODO layout find out why this extra media query is necessary for the fb nav bar
@media screen and (min-width: @breakpoint-tablet-min) and (max-width: @breakpoint-tablet-max) {
	.App.fullscreen .Nav {
		height: 118px; // should be height of Nav-Button
	}
	.App.fullscreen .Nav-Button {
		padding-top: @padding-m + 20px + 40px;
	}
}

@media screen and (min-width: @breakpoint-desktop-min) {
	.App.integrated .Nav {
		background:	@color-highlight;
	}
	.App.integrated .Nav:before {
		content: '';
		display: block;
		background-image: url("/img/fb/schwung-2500x250.svg"); // 1165px x 89px
		padding-bottom: calc(~"100% *" @schwung-height-desktop/@schwung-width-desktop ~"+ 2px");
		background-repeat: no-repeat;
		background-size: cover;
		width: 100%;
		position: relative;
	}
	.App.fullscreen .Nav {
		//margin: 0 auto;
		//max-width: @fb-desktop-content-width;
		position: absolute;
		top: 70%;
		width: 100%;
	}

	.Nav-Buttons {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 1060px;
	}

	.Nav-Buttons.single {
		justify-content: center;
	}

	.no-touch .Nav-Button {
		transition: all .3s ease;
	}

	.App.fullscreen .Nav-Button {
		width: 200px;
		height: 60px;
		text-align: center;

		font-size: @fb-fs-heading; // desktop
		font-weight: @font-weight-bold;
		padding: @padding-m;
		border-radius: 1000px;
	}
	.App.fullscreen .Nav-Buttons.single .Nav-Button {
		width: auto;
	}

	.no-touch .App.fullscreen .Nav-Button:hover,
	.App.fullscreen .Nav-Button:active {
		background: @radiobutton-text-selectedbg;
		color: @color-white;
	}

	.Nav-Continue {
		color: @color-white;
		background:	@color-highlight;
	}

	.Nav-Back {
		border: 3px solid @color-highlight;
	}

	.no-touch .Nav-Back:hover, .Nav-Back:active {
		border: 3px solid @radiobutton-text-selectedbg;
	}
}
