.wrap {
	display: flex;
	flex-flow: column;
}
.flex-r {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: @padding-s;
}

.fb-paragraph {
	line-height: @line-height-s;
}

.fb-paragraph + .fb-paragraph {
	margin: @padding-m 0;
}

.heading {
	font-size: @fb-fs-heading;
	font-weight: @font-weight-bold;
	margin: @padding-l 0 @padding-m 0;
	line-height: @line-height-s;
}

@fb-input-height: 36px;

// Override geko
.fb-input, .fb-textarea, .fb-input-checkbox.input-checkbox {
	border: 2px solid @fb-input-border;
}

.fb-input, .fb-textarea {
	display: block;
	padding: (@padding-m / 5);
	width: 100%;
	border-radius: @border-radius;
	text-align: left;
	-webkit-appearance: none;
}
.fb-input{
	height: @fb-input-height;
}

.fb-field {
	margin-top: @padding-m;
}
.fb-field--vertical {
	display: flex;
	flex-flow: column;
	gap: @padding-s;
}

.fb-input:focus, .fb-textarea:focus {
	border: 2px solid @color-highlight;
}

.fb-input.fb-date {
	width: 200px;
	padding-right: @fb-input-height + 2;
	text-align: center;
}

.fb-date {
	position: relative;
}

.fb-date::-webkit-calendar-picker-indicator {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: auto;
	color: transparent;
	background: transparent;
}
.fb-date::-webkit-inner-spin-button {
	z-index: 1;
}
.fb-date::-webkit-clear-button {
	z-index: 1;
}

.fb-inputwrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	margin-bottom: @padding-s;
}

.fb-inputwrapper .fa-calendar {
	background-color: @color-white;
	color: @color-highlight;
	border: 2px solid @color-highlight;
	border-radius: @border-radius;
	text-align: center;
	width: @fb-input-height;
	height: @fb-input-height;
	padding: 3px;
	font-size: 24px;
	margin-left: -@fb-input-height;
	pointer-events: none;
	z-index: 1;
}

.fb-label {
	font-size: @fb-fs-heading;
	font-weight: @font-weight-bold;
	line-height: @line-height-s;
	display: block;

}
.flex-r .fb-label {
	width: 100px;
	text-align: right;
}
.flex-r .fb-input {
	width: inherit;
	flex-grow: 1;
}

.fb-validation-error {
	text-align: left;
	padding: @padding-xs;
	color: @color-error;
	line-height: @line-height-s;
}
