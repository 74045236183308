.Budget-button {
	width: 25%;
}

.Budget-icon {
	width: 100%;
}

.Budget-slider-wrapper {
	position: relative;
	height: 40px;
}

.Budget-slider-label-tick {
	width: 2px;
	height: 12px;
	background: @slider-track-color;
	position: absolute;
	top: 12px;
}

.Budget-slider-mark {
	width: 1px;
	height: 55px;
	background: @slider-tick-color;
	position: absolute;
	left: 37%;
	bottom: -14px; // line-height of Budget-slider-labels
}
.Budget-slider-mark:after {
	content: '*';
	display: block;
	position: absolute;
	bottom: 9px;
	right: -10px;
}

.Budget-slider.Slider-slider {
	width: 100%;
	position: relative;
	z-index: 1;
}

.Budget-slider-labels {
	display: flex;
	justify-content: space-between;
}
.Budget-slider-label {
	width: 50px;
}
.Budget-slider-label:last-child {
	text-align: right;
}

.Budget-note {
	text-align: left;
	line-height: @line-height-s;
}
.Budget-impressions-title {
}

.Budget-impressions {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
}

.Budget-impression {
	display: flex;
	flex-flow: column;
	align-items: center;
	margin: @padding-s;
}

.Budget-impression-image {
	width: 86px;
	height: 86px;
}

@media screen and (min-width: @breakpoint-tablet-min) {
	.Budget-impression-image {
		width: 130px;
		height: 130px;
	}
}

.Budget-impression-text {
	color: @radiobutton-icon-text;
	text-transform: uppercase;
	font-weight: @font-weight-bold;
	margin-top: @padding-s;
}
