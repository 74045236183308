.Counter {}

.Counter-content {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	align-items: center;
	justify-content: center;
}
.Counter-content-col {
	min-width: 120px;
	flex-shrink: 0;
}
.Counter-content-col.icon {
	height: 60px;
}

.Counter-content-col.fb-paragraph {
	display: flex;
	flex-flow: column;
	align-items: center;
	text-align: center;
}
.Counter-icon {
	height: 60px;
	width: auto;
	margin-right: 10px;
	opacity: 0.5;
}

.Counter-subtitle {
	color: @radiobutton-icon-text;
	text-transform: uppercase;
	font-weight: @font-weight-bold;
	padding-bottom: 5px;
	height: 24px; // line-height: 1.3 is 18.2px rounded
	flex-shrink: 0;
}

.Counter-value {
	font-family: @font-fancy;
	font-style: italic;
	//color: @color-highlight;
	font-size: 60px;
	line-height: 1;
	padding-bottom: 10px;
	overflow: visible;
	height: 70px; // line-height + padding
	flex-shrink: 0;
}
.Counter-buttons {
	height: 50px; // diameter + margin
	flex-shrink: 0;
}

@counter-circle-diameter: 40px;

.Counter-button {
	width: @counter-circle-diameter;
	height: @counter-circle-diameter;
	border-radius: 50%;
	border: 3px solid @color-highlight;
	color: @radiobutton-icon-text;
	font-size: 30px;
	text-transform: uppercase;
	font-weight: @font-weight-bold;
	line-height: 30px;
	display: inline-block;
	flex-shrink: 0;
	flex-grow: 0;
	margin: 5px;
}

.Counter-button-inner {
	position: relative;
	top: -3px;
}

.no-touch .Counter-button:hover, .no-touch .Counter-button:active {
	color: @color-white;
	border: 3px solid @radiobutton-text-selectedbg;
	background: @radiobutton-text-selectedbg;
}

.visible-focus .Counter-button:focus {
	box-shadow: @focus-outline;
}
