.Text-icon-wrapper {
	display: flex;
	justify-content: center;
}

.Text-icon {
	width: 86px;
	margin-bottom: @padding-m;
}

.Text-greeting2 {
	font-family: @font-fancy;
	font-style: italic;
	font-size: @fb-fs-listitem;
	color: @list-item-color;
	margin: @padding-m 0;
}
