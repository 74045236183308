.ProgressIndicator {
	margin-left: @padding-m;
	margin-top: @padding-m;
	width: @processIndicator-circle-diameter;
	height: @processIndicator-circle-diameter;
	border-radius: 50%;
	--coreColor:	@color-white;
	--processDoneColor:	#FABB00;
	--processOpenColor:	#92907B;
}
