.CloseButton {
	margin-right: @padding-m;
	margin-top: @padding-m;

}
.CloseButton:before {
	content: '╳';
	color: @close-button-color;
	font-size: 20px;
	width: 24px;
	height: 24px;
}
.visible-focus .CloseButton:focus { box-shadow: @focus-outline; }
