.Selectlist-item {
	width: 100%;
	height: 86px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	border: 1px solid @border-color-grey;
	border-radius: @border-radius-l;
	margin-bottom: @padding-xs;
}

.no-touch .Checkbox-label.unchecked .Selectlist-item:hover {
	background-color: @list-item-bgcolor-hover;
}
.no-touch .Checkbox-label.checked .Selectlist-item:hover .Selectlist-item-content {
	background-color: @list-item-bgcolor-hover;
}

.visible-focus .Checkbox input:focus ~ .Checkbox-label .Selectlist-item { box-shadow: @focus-outline; }

.Selectlist-item:not(:last-child), .Selectlist-modal-item:not(:last-child) {
	margin-bottom: (@padding-m / 5);
}

.Selectlist-item-icon {
	width: 82px;
	height: 82px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	margin-right: @padding-s;
	border: 12px solid transparent;
	z-index: 1;
	padding: 3px;
}

.Selectlist-item-content {
	padding: @padding-xs;
	width: calc(100% - 43px);
	height: 100%;
	text-align: left;
	border: 1px solid transparent;
	position: relative;
	display: flex;
	align-items: center;
	flex-flow: row wrap;
}

.Selectlist-item-content-text {
	font-family: @font-fancy;
	font-style: italic;
	font-size: @fb-fs-listitem;
	color: @list-item-color;
	width: 100%;
}

.Selectlist-edit-text {
	position: absolute;
	top: 2px;
	right: 2px;
	font-size: @wp-fs-s;
	text-decoration: underline;
	padding: 4px 4px 20px 20px;
}

.no-touch .Selectlist-edit-text:hover {
	color: @list-item-color-hover;
	background-color: @list-item-bgcolor-hover;
}

.visible-focus .Selectlist-edit-text:focus {
	box-shadow: @focus-outline;
}

.Selectlist-edit-icon {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 60px;
	height: 60px;
	padding: 10px 10px 30px 30px;
}
.Selectlist-edit-icon .fa:before {
	position: absolute;
	top: 2px;
	right: 2px;
	background-color: @checkbox-label-checked;
	color: @color-white;
	border: 1px solid transparent;
	border-radius: 50%;
	font-size: 18px;
	width: 28px;
	height: 28px;
	text-align: center;
	padding: 4px;
	display: block;
}
.no-touch .Selectlist-edit-icon:hover .fa:before {
	background-color: @color-white;
	color: @checkbox-label-checked;
	box-shadow: inset 0 0 0 -1px @checkbox-label-checked;
}

.visible-focus .Selectlist-edit-icon:focus .fa:before {
	box-shadow: @focus-outline;
}

.Checkbox-label.checked > .Selectlist-item {
	border: 1px solid transparent;
}

.Checkbox-label.checked > .Selectlist-item > .Selectlist-item-icon {
	border-radius: 50%;
	background-color: @checkbox-label-checked;
	border: 12px solid @checkbox-label-checked;
}

.Checkbox-label.checked > .Selectlist-item > .Selectlist-item-content {
	margin-left: -43px;
	padding-left: 39px + @padding-xs;
	border: 1px solid @border-color-grey;
	border-radius: @border-radius-l;
}
.Checkbox-label.checked > .Selectlist-item > .Selectlist-item-content > .Selectlist-item-content-text {
	color: @checkbox-label-checked;
}

.Checkbox-label.checked > .Selectlist-item > .Selectlist-item-content > .Selectlist-item-content-text.withChildren {
	line-height: 32px;
}

.Selectlist-sublist-item {
	display: inline-block;
	font-size: @wp-fs-s;
	margin-right: 2px;
}

.Selectlist-sublist-item + .Selectlist-sublist-item {
	margin-top: 2px;
}

.Selectlist-modal-item {
	padding: @padding-s;
	border-radius: @border-radius-l;
	border: 1px solid @modal-listitem-border;
	text-align: center;
	margin-bottom: @padding-xxs;
}

.no-touch .Selectlist-modal-item:hover {
	background-color: @list-item-bgcolor-hover;
}

.visible-focus .Checkbox input:focus ~ .Checkbox-label .Selectlist-modal-item { box-shadow: @focus-outline; }

.Checkbox-label.checked > .Selectlist-modal-item {
	border: 1px solid transparent;
	background-color: @checkbox-label-checked;
	color: @color-white;
}

.no-touch .Checkbox-label.checked > .Selectlist-modal-item:hover {
	border: 1px solid transparent;
	background-color: @checkbox-label-checked;
	color: @color-white;
}

.Selectlist-modal-title {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.Selectlist-modal-title-text {
	font-family: @font-fancy;
	font-style: italic;
	font-size: @fb-fs-listitem;
	color: @list-item-color;
}
.Selectlist-modal-title-icon {
	width: 40px;
	height: 40px;
	margin-right: @padding-s;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}
