@import "@v3/geko/src/less/variablesInternal.less";
@import "@v3/geko/src/less/variables.less";

@import "../../../common/src/less/genericOverrides.less";
@import "../../../common/src/less/variables.less";

@import "inc/app.less";
@import "inc/autocomplete.less";
@import "inc/budget.less";
@import "inc/checkbox.less";
@import "inc/closebutton.less";
@import "inc/contact.less";
@import "inc/counter.less";
@import "inc/flight.less";
@import "inc/modal.less";
@import "inc/nav.less";
@import "inc/progressindicator.less";
@import "inc/radiobutton.less";
@import "inc/slider.less";
@import "inc/selectlist.less";
@import "inc/text.less";
@import "inc/util.less";

//-------------------------------------------------------------------------------
// functional colors
@app-integrated-border-desktop:	@fb-grey-06;
@border-color-grey:				@fb-grey-02;

@radiobutton-icon-border:		@fb-grey-04;
@radiobutton-icon-text:			@fb-grey-03;
@radiobutton-text-selectedbg:	@fb-grey-05;

@checkbox-label-checked:		@fb-grey-05;
@modal-listitem-border:			@fb-grey-06;

@slider-thumb-border:			@fb-grey-02;
@slider-track-color:			@fb-grey-04;
@slider-tick-color:				@fb-grey-02;

@fb-input-border:				@fb-grey-04;

@list-item-color:				@fb-grey-03;
@list-item-color-hover:			@fb-grey-05;
@list-item-bgcolor-hover:		@fb-grey-04;

@close-button-color:			@fb-grey-02;
@text-color:					@fb-grey-01;

@autocomplete-bg:				@fb-grey-04;
@autocomplete-bg-selected:		@fb-grey-05;

//-------------------------------------------------------------------------------
// other variables
@schwung-width-desktop:		2500;
@schwung-height-desktop:	250;
@schwung-width-mobile:		1200;
@schwung-height-mobile:		132;

@fb-desktop-content-width:	600px;

@processIndicator-circle-diameter: 50px;

//===============================================================================
// Outer CSS overrides
//===============================================================================

body.fb-fullheight {
	width: 100%;
	height: 100%;
	overflow: hidden;
}
