.App {
	color: @text-color;
	line-height: 1;
}

.App.hidden {
	opacity: 0;
	visibility: hidden;
}
.App.visible {
	opacity: 1;
	visibility: visible;
}

// On route pages questionnaire should be included (can be opened by the starter) but should not be visible on top
.landingpage-route .App.integrated.visible {
	opacity: 0;
	visibility: hidden;
}

.App-starter-button {
	display: none;
}

.App.integrated {
	border: 1px solid @app-integrated-border-desktop;
}

.App.fullscreen {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: white;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 4;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.App-inner {
	background-color: @color-white;
}

.App-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.Logo {
	width: 184px;
}

.ScreenDisplay {
	text-align: center;
	padding: @padding-m;
}

@media screen and (max-width: @breakpoint-tablet-max) {
	.ScreenDisplay {
		width: 100%;
	}

	.App.fullscreen .ScreenDisplay {
		padding-bottom: calc(~"100% *" @schwung-height-mobile/@schwung-width-mobile ~"+ 100px");
		// overflow-y: auto; // conflicts with modal display on ios
		-webkit-overflow-scrolling: touch;
	}
}

@media screen and (min-width: @breakpoint-tablet-min) {
	.ScreenDisplay {
		margin: 0 auto;
		max-width: @fb-desktop-content-width + 2 * @padding-m;
	}

	.App.fullscreen {
		-webkit-backface-visibility: hidden;
	}

	.App.fullscreen .App-inner {
		margin-bottom: 80px;
	}
}
