.Flight {
	display: flex;
	flex-flow: column;
	align-items: center;
}

.Flight-icon {
	display: block;
	width: 60px;
	height: 60px;
	opacity: 0.5;
}
