.Slider {
	//padding: 10px 0px;
	max-width: 500px;
	margin: auto;
}

.Slider-iconwrapper {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	width: 100%;
}

.Slider-icon {
	width: auto;
	height: 60px;
	opacity: 0.5;
}

.Slider-label {
	font-weight: @font-weight-bold;
	margin: 6px 0px 10px 0px;
}

.Slider-iconlabelwrapper {
	width: 150px;
}

.Slider-sliderwrapper {
	position: relative;
	text-align: center;
	margin-top: -5px;
}

.Slider-slider {
	width: 75%;
	/* styling suggested by customer
	width: 100%;
	*/
	cursor: pointer;
	background-color: transparent;
	-webkit-appearance: none;
	position: relative;
	z-index: 1;
	height: 36px;
	margin-top: -5px;
	margin-bottom: -5px;
}

.Slider-centerline {
	position: relative;
	left: 50%;
	top: -24px;
	width: 3px;
	height: 20px;
	background-color: @slider-track-color;
	margin-left: -1.5px;
	border-radius: @border-radius;
	// this z-index makes all content on the next screens disappear on iOS
	// z-index: -1;
}

.Slider-centerline::-webkit {
	margin-top: -11.25px;
}

/* LESS-Mixins to avoid redundant code for different browsers */

.slider-thumb-styling {
	height: 32px;
	width: 32px;
	border: 2px solid @slider-thumb-border;
	border-radius: 50%;
	background: @color-highlight;
	//box-shadow: 1px 1px 2px lightgrey;
}

.slider-track-styling {
	width: 95%;
	height: 5px;
	border-radius: 2px;
	background: @slider-track-color;
}

/* WebKit/Blink */
.Slider-slider::-webkit-slider-thumb {
	.slider-thumb-styling;
	-webkit-appearance: none;
	margin-top: -14px;
}

.Slider-slider::-webkit-slider-runnable-track {
	.slider-track-styling;
	margin-top: 10px;
	margin-bottom: 0px;
}

/* Firefox */
.Slider-slider::-moz-range-thumb { .slider-thumb-styling; }
.Slider-slider::-moz-range-track { .slider-track-styling; }
.Slider-slider::-moz-focus-outer { border: 0; }

/* IE */
.Slider-slider::-ms-thumb {
	.slider-thumb-styling;
	margin-top:0px;
}
.Slider-slider::-ms-track { .slider-track-styling; }
