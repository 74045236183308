@radio-circle-diameter: 86px;

.RadioGroup {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

.RadioButton:not(last-child) {
	margin-right: @padding-s;
}

.RadioButton-label {
	display: flex;
	text-align: center;
	flex-flow: column;
	align-items: center;
	cursor: pointer;
}

.RadioGroup.numbers .RadioButton-label,
.RadioGroup.text .RadioButton-label {
	width: @radio-circle-diameter;
	height: @radio-circle-diameter;
	border-radius: 50%;
}

.RadioGroup.numbers .RadioButton-label {
	border: 1px solid @radiobutton-icon-border;
	color: @color-highlight;
	font-family: @font-fancy;
	font-style: italic;
	font-size: @fb-fs-radiobutton-icon;
	line-height: 76px;
}

.RadioGroup.numbers .RadioButton-label.checked,
.no-touch .RadioGroup.numbers .RadioButton-label:hover {
	border: 1px solid @radiobutton-text-selectedbg;
	background: @radiobutton-text-selectedbg;
	color: @color-white;
}

.RadioGroup.text .RadioButton-label {
	border: 3px solid @color-highlight;
	color: @radiobutton-icon-text;
	font-size: @fb-fs-radiobutton-text;
	text-transform: uppercase;
	font-weight: @font-weight-bold;
	line-height: 80px;
}

.RadioGroup.text .RadioButton-label.checked,
.no-touch .RadioGroup.text .RadioButton-label:hover {
	border: 3px solid @radiobutton-text-selectedbg;
	background: @radiobutton-text-selectedbg;
	color: @color-white;
}

.RadioGroup.icon .RadioButton-text {
	color: @radiobutton-icon-text;
	text-transform: uppercase;
	font-weight: @font-weight-bold;
	max-width: 140px;
}

.RadioButton-icon {
	width: @radio-circle-diameter;
	height: @radio-circle-diameter;
	border: 1px solid @radiobutton-icon-border;
	border-radius: 50%;
	//background-size: 85%;
	background-repeat: no-repeat;
	background-position: center center;
	margin-bottom: @padding-s;
}

.RadioGroup.icon .RadioButton-label.checked .RadioButton-icon,
.no-touch .RadioGroup.icon .RadioButton-label:hover .RadioButton-icon {
	background-color: @checkbox-label-checked;
	border: 1px solid transparent;
}

.visible-focus .RadioButton input:focus ~ .RadioButton-label {
	box-shadow: @focus-outline;
}
