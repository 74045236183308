
.modal {
	display: none;
}

.modal.visible {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,.6);
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-inner {
	background: @color-white;
	border-radius: @border-radius-l;
	width: 80vw;
	height: auto;
	max-height: 80vh;
	max-width: 400px;
	position: relative;
}

.modal-content {
	padding: @padding-m @padding-m 60px @padding-m;
}

.modal-close {
	position: absolute;
	right: 0;
	top: 0;
}

.modal-nav {
	background: @color-highlight;
	position: absolute;
	bottom: 0;
	width: 100%;
	border-bottom-left-radius: @border-radius-l;
	border-bottom-right-radius: @border-radius-l;
}
.modal-button-save {
	width: 100%;
	text-align: center;
	padding: @padding-s;
	color: @color-white;
	font-size: @fb-fs-heading; // desktop
	font-weight: @font-weight-bold;
}
.visible-focus .modal-button-save:focus { box-shadow: @focus-outline; }
